"use client";

import { useEffect, useState } from "react";

import { createClient } from "@/lib/supabase/client";

export const useIsSignedIn = () => {
  const [signedIn, setSignedIn] = useState(false);

  const supabase = createClient();

  useEffect(() => {
    (async () => {
      const signedIn = await supabase.auth.getSession();

      setSignedIn(!!signedIn.data.session?.user?.id);
    })();
  }, [supabase.auth]);

  return signedIn;
};

export const useSignOut = () => {
  const supabase = createClient();

  return supabase.auth.signOut;
};
