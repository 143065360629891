"use client";

import { Button } from "@kamio/design-system/components/ui/button";
import { Card } from "@kamio/design-system/components/ui/card";
import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";

import KamioKashLogo from "@/components/icons/KamioKashLogo";
import { useIsSignedIn } from "@/hooks/use-is-signed-in";
import { signOut } from "@/lib/supabase/auth";

export default function Error({ reset, error }: { error: unknown; reset: () => void }) {
  const isSignedIn = useIsSignedIn();

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <Card className="mx-auto my-4 flex max-w-xl flex-col p-8 md:p-12">
      <KamioKashLogo className="mx-auto" />
      <h2 className="text-xl font-bold">Oh no!</h2>
      <p className="my-2">There was an issue. This could be a temporary issue, please try your action again.</p>
      <Button className="mx-auto mt-4 flex w-full items-center justify-center" onClick={() => reset()}>
        Try Again
      </Button>

      {isSignedIn && (
        <>
          <p className="my-2 text-xs mt-4">Or try logging out and back in again to refresh your session</p>
          <Button
            className="mx-auto flex w-full items-center justify-center"
            onClick={async () => {
              await signOut();

              window.location.reload();
            }}
            variant="outline"
          >
            Sign Out
          </Button>
        </>
      )}
    </Card>
  );
}
